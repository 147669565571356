/* About Us section - Background colors */
.profile-section-left {
  background-color: #34495e; /* Light blue background for Travis */
  padding: 20px;
  border-radius: 10px;
}

.profile-section-right {
  background-color: #e67e22; /* Light red background for Addison */
  padding: 20px;
  border-radius: 10px;
}

/* Adjust profile images */
.profile-image {
  width: 100%;
  border-radius: 5%;
  height: auto; /* Maintain aspect ratio */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Adds subtle shadow */
}
