/* Sticky Navbar */
.navbar-section {
  background-color: #2c3e50; /* Blue background */
  padding: 10px 20px;
  box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  z-index: 1000; /* Ensure it stays above other content */
}

/* Ensure the navbar toggle (hamburger) icon is white */
.navbar-toggler {
  border-color: rgba(
    255,
    255,
    255,
    0.6
  ); /* Set the border color for the toggle icon */
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.85%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  /* This is the default Bootstrap icon with white lines */
}

/* Brand Name Styling */
.brand-name {
  font-weight: bold;
  font-size: 1.5rem;
}

.brand-name .hg-name {
  color: white; /* "HG" is white by default */
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

.brand-name .hg-name:hover {
  color: #e67e22; /* "HG" turns blue (#3498DB) on hover */
}

.brand-name .reliant-name {
  color: #e67e22; /* "Reliant" stays orange at all times */
  font-weight: bold;
}

/* Nav Links */
.nav-link {
  color: white !important; /* Ensure the text stays white */
  margin-left: 20px;
  font-size: 1.1rem;
}

.nav-link:hover {
  color: #e67e22 !important; /* Orange hover effect for links */
}

/* Active Link */
.nav-link.active {
  color: #e67e22 !important; /* Orange color for the active link */
}

/* Brand Name Active State */
.navbar-brand:focus,
.navbar-brand:active {
  color: white !important; /* Ensure "HG" stays white when active */
}

.reliant-name:focus,
.reliant-name:active {
  color: #e67e22 !important; /* "Reliant" stays orange when clicked or focused */
}

/* Responsive behavior */
@media (max-width: 768px) {
  .nav-link {
    margin-left: 0;
    margin-top: 10px;
  }
}
